<template>
  <div>
    <b-overlay
      :show="updatingStatus"
      rounded="sm"
    >
      <my-table
        title=""
        :rows="rows"
        :columns="columns"
        :date-range-enabled="true"
        :date-range-field="'pickup_date'"
        :is-loading="isLoading"
      >
        <template v-slot="{props}">
          <span v-if="props.column.field === 'delivery_id'">
            <b-button
              block
              variant="primary"
              :to="{
                name: 'delivery-summary',
                params: { deliveryId: props.row.delivery_id },
              }"
            >
              {{ props.row.delivery_id }}
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'order.id'">
            <b-button
              block
              variant="primary"
              :to="{
                name: 'order-details',
                params: { id: props.row.order.id },
              }"
            >
              {{ props.row.order.reference_id }}
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'order.status.text'">
            <b-button
              block
              variant="success"
              @click.prevent="handleShowEvidence(props.row.delivery_id)"
            >
              {{ props.row.order.status.text }}
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'total_earn'">
            {{ $helpers.formatTotal(props.row.total_earn | 0) }}
          </span>

          <span v-else-if="props.column.field === 'order.delivery_date'">
            {{ $helpers.formatDateTime(props.row.order.delivery_date) }}
          </span>

          <span v-else-if="props.column.field === 'payment_status.text'">
            <b-badge
              :variant="props.row.payment_status.color_name"
              class="badge-padding text-white font-weight-bolder w-100"
            >
              {{ props.row.payment_status.text }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'actions'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-for="(item, index) in status"
                :key="index"
                @click="handleUpdateStatus(props.row.id, item.id)"
              >
                {{ item.text }}
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </my-table>
      <template #overlay>
        <div class="text-center">
          <b-spinner
            large
            variant="primary"
          />
          <p
            class="text-primary"
          >
            Updating Status...
          </p>
        </div>
      </template>
    </b-overlay>
    <b-modal
      id="modal"
      v-model="showEvidence"
      title="Uploaded Documents"
      size="lg"
      scrollable
      ok-only
      @hide="showEvidence = !showEvidence"
    >
      <b-card
        v-for="(evidence, key) in evidencePhoto"
        :key="key"
        :header="transFormHeader(key)"
        header-text-variant="white"
        header-tag="header"
        header-bg-variant="secondary"
      >
        <b-card-body>
          <b-img
            :src="evidence"
            class="w-100"
            fluid
            :alt="transFormHeader(key)"
          />
        </b-card-body>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import { MyTable } from '@/components'
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    MyTable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      isLoading: false,
      updatingStatus: false,
      columns: [
        {
          label: 'Order ID',
          field: 'order.id',
          thClass: 'text-left',
          tdClass: 'align-middle text-left',
          type: 'number',
          sortable: false,
        },
        {
          label: 'Delivery ID',
          field: 'delivery_id',
          thClass: 'text-left',
          tdClass: 'align-middle text-left',
          type: 'number',
          sortable: false,
        },
        {
          label: 'Company Name',
          field: 'company_name',
          tdClass: 'align-middle',
        },

        {
          label: 'Delivery Date',
          field: 'order.delivery_date',
          tdClass: 'align-middle',
        },
        {
          label: 'Total Earn',
          field: 'total_earn',
          thClass: 'text-left',
          tdClass: 'align-middle text-left',
          type: 'decimal',
        },
        {
          label: 'Delivery Status',
          field: 'order.status.text',
          thClass: 'text-left',
          tdClass: 'align-middle text-left',
          sortable: false,
        },
        {
          label: 'Payment Status',
          field: 'payment_status.text',
          thClass: 'text-left',
          tdClass: 'align-middle text-left',
          sortable: false,
        },
        {
          label: 'Actions',
          field: 'actions',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
      ],
      rows: [],
      showEvidence: false,
      evidencePhoto: [],
      status: [
        { id: 1, text: 'In Process' },
        { id: 2, text: 'Paid' },
        { id: 3, text: 'On Hold' },
      ],
    }
  },
  created() {
    this.fetchTransactions()
  },
  methods: {
    async fetchTransactions() {
      this.isLoading = true
      const response = await axios.get('/admin/transactions')
      if (response.status === 200) {
        this.rows = response.data
        this.isLoading = false
      }
    },
    async handleUpdateStatus(transactionId, status) {
      this.updatingStatus = true
      const response = await axios.patch(`/admin/transactions/${transactionId}`, { payment_status: status })
      if (response.status === 200) {
        this.updatingStatus = false
        this.$swal({
          icon: 'success',
          title: 'Payment Status Updated!',
          text: 'Successfully updated the payment status.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(() => {
          this.fetchTransactions()
        })
      }
    },
    handleShowEvidence(deliveryId) {
      this.showEvidence = !this.showEvidence
      const photos = this.rows
        .filter(row => row.delivery_id === deliveryId)
        .map(row => ({
          delivered_photo: row.order.delivered_photo,
          pickup_photo: row.order.pickup_photo,
          good_return_note_photo: row.order.good_return_photo,
        }))
      this.evidencePhoto = { ...photos[0] }
    },
    transFormHeader(title) {
      return title.replace(/_/g, ' ').toUpperCase()
    },
  },
}
</script>
